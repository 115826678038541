// extracted by mini-css-extract-plugin
export var container = "contactUs-module--container--qPXqq";
export var arrowsImage = "contactUs-module--arrows-image--iM21S";
export var content = "contactUs-module--content--ioupX";
export var nameInput = "contactUs-module--nameInput--n37Cf";
export var emailInput = "contactUs-module--emailInput--5WV69";
export var bodyInput = "contactUs-module--bodyInput--OW6Nr";
export var errorColor = "contactUs-module--errorColor--yI-uS";
export var submitButton = "contactUs-module--submitButton--C-0xI";
export var contactInfo = "contactUs-module--contact-info--4GSo3";
export var contactLink = "contactUs-module--contact-link--854Ck";
export var contactSocials = "contactUs-module--contact-socials--0kzdU";
export var socialItem = "contactUs-module--social-item--MQqEU";
export var shareLink = "contactUs-module--share-link--oylRm";
export var opacityBackground = "contactUs-module--opacity-Background--7teZR";
export var active = "contactUs-module--active--J9mDQ";