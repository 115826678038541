import React from 'react';
import cx from 'classnames';
import * as style from './directionButton.module.scss';
import PropTypes from 'prop-types';
import Img from '../Img/Img';
import AnchorLink from '../AnchorLink/AnchorLink';
import arrowIcon from './assets/arrowIcon.svg';

const DirectionButton = (props) => {
    const {
        direction,
        id
    } = props;
    return(
        <div className={cx(style.arrowButtonContainer, style[direction])}>
            <AnchorLink id={id}>
                <Img src={arrowIcon} alt="arrow icon"/>
            </AnchorLink>
        </div>
    );
};

DirectionButton.propTypes = {
    direction: PropTypes.oneOf(['up', 'down']),
    id: PropTypes.string
};
export default DirectionButton;