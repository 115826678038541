import React from 'react';
import TextHeader from '../TextHeader/TextHeader';
import IconedText from '../IconedText/IconedText';
import qualityImg from './assets/quality.svg';
import detailsImg from './assets/details.svg';
import availabilityImg from './assets/availability.svg';
import teamImg from './assets/team.svg';
import detailSmallImg from './assets/details-sml.svg';
import availabilitySmallImg from './assets/availability-sml.svg';
import qualitySmallImg from './assets/quality-sml.svg';
import teamSmallImg from './assets/team-sml.svg';
import * as style from './whyUs.module.scss';
import detailsBg from './assets/detailsBg.svg';
import qualitiyBg from './assets/qualityBg.svg';
import teamBg from './assets/teamBg.svg';
import availabilityBg from './assets/availabilityBg.svg';
import cx from 'classnames';
import * as IconTextStyle from '../IconedText/iconedText.module.scss';
import { isMobile } from 'react-device-detect';
import Container from '../Container/Container';

const elementsData = [
    {
        title: 'Attention to Details.',
        content: 'We always think big but we care about the little details of our projects, which makes all the difference.',
        image: isMobile ? detailSmallImg : detailsImg,
        backgroundImg: detailsBg
    },
    {
        title: 'Expert Crtafters.',
        content: 'We strive to achieve the highest professional standards in order to create the finest products out there, no less.',
        image: isMobile ? qualitySmallImg : qualityImg,
        backgroundImg: qualitiyBg
    },
    {
        title: 'Top-Tier Technology.',
        content: 'We practice top-tier technology and aim to provide you with the most up-to-date knowledge.',
        image: isMobile ? teamSmallImg : teamImg,
        backgroundImg: teamBg
    },
    {
        title: 'Partnership.',
        content: 'We believe in communication and close collaboration throughout a project\'s life. Our developers are a real part of the team.',
        image: isMobile ? availabilitySmallImg : availabilityImg,
        backgroundImg: availabilityBg
    },
];

const WhyUs = () => {

    return (
        <Container className={style.container} id="whyUs" color="white" ariaLabel="Why us section">
            <TextHeader color="primary">Why Choose Us?</TextHeader>
            <div className={style.contentWrapper}>
                {elementsData.map(({ title, content, image, backgroundImg }) => (
                    <IconedText
                        key={title}
                        title={title}
                        content={content}
                        image={image}
                        itemClassName={style.textItem}
                    >
                        <div className={cx(IconTextStyle.blurPicture)}
                             style={{
                                 backgroundImage: `url(${backgroundImg})`,
                             }}
                        />
                    </IconedText>

                ))}
            </div>
        </Container>
    );
};

export default WhyUs;
