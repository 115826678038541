import React, { useState } from 'react';
import * as style from './customers.module.scss';
import TextHeader from '../TextHeader/TextHeader';
// import ivix from '../Clients/assets/ivix.svg';
// import artai from '../Clients/assets/artai.svg';
// import hiauto from '../Clients/assets/hiauto.svg';
// import perception from '../Clients/assets/perception.svg';
// import phenom from '../Clients/assets/phenom.svg';
import Img from '../Img/Img';
import { isMobile } from 'react-device-detect';
// import hiautoDisabled from '../Clients/assets/hiauto_disabled.svg';
// import perceptionDisabled from '../Clients/assets/perception_disabled.svg';
// import artaiDisabled from '../Clients/assets/artai_disabled.svg';
// import ivixDisabled from '../Clients/assets/ivix_disabled.svg';
// import phenomDisabled from '../Clients/assets/phenomDisabled.svg';
import Container from '../Container/Container';
import PropTypes from 'prop-types';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';
import './carousel.css';
import Button from '../Nav/Button';
import personPlaceholder from './assets/person-placeholder.png';
import bennyReich from './assets/Benny-Reich.jpg';
import eyalFisher from './assets/Eyal-Fisher.jpg';
import eyalShapira from './assets/Eyal-Shapira.jpg'
import idoZur from './assets/Ido-Zur.jpg';
import matanFattal from './assets/Matan-Fattal.jpg';

const customersSentence = !isMobile ? 'What Our Clients Say About Us?' : 'What Clients say?';

const items = [
    {
        name: 'Matan Fattal',
        role: 'Co-Founder, CEO at IVIX',
        text: 'Working with Webiya is a pleasure. They delivered exceptional quality on time with better ROI compared to what we were able to achieve internally.',
        image: matanFattal
    },
    {
        name: 'Eyal Shapira',
        role: 'Co-Founder & CTO at Hi Auto',
        text: 'Igal and his team are great professionals who move very quickly. They get the job done and communicate very well even when not everything is well defined. They\'ve enabled us to quickly get to working complex front end products. I highly recommend Webiya.',
        image: eyalShapira
    },
    {
        name: 'Eyal Fisher',
        role: 'Co-Founder & CTO at Art AI',
        text: 'We love working with Webiya. They are great people and they write great quality code. We have tried working with different agencies before, but nothing compares to working with Igal and his team.',
        image: eyalFisher
    },
    {
        name: 'Ido Zur',
        role: 'Product Manager at Phenom',
        text: 'Webiya was there for us starting our first FED team and guiding them along the way, responsive professional and effective collaboration.',
        image: idoZur
    },
    {
        // logo: perception,
        // logoDisabled: perceptionDisabled,
        altText: 'Perception logo',
        name: 'Benny Reich',
        role: 'VP Products at Perception Point',
        text: 'We have been collaborating with Webiya on our Frontend Webapp\'s needs. Webiya was able to easily integrate into our work processes and strengthen our Frontend team while understanding our technical needs and business goals. We view Webiya as not just a vendor but more like a partner to help us provide a Webapp to our customers with a great UX and accurate UI.',
        image: bennyReich
    },
    {
      name: 'Tomer Shoshani',
      role: 'Managing Partner at Humanity Ltd.',
      text: 'I would like to thank the Webiya team and especially to Igal the CEO, this is an excellent & professional company, with a very high level of service and with a very fast response for any issue or needs that I ever needed. Webiya has an excellent service, high availability and very kind and supporting team to get the right service. Thank you! and keep up with the good working.',
      image: personPlaceholder
  },
];


const Customers = () => {
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length -1 ? 0 : activeIndex +1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const markLogo = (e, index) => {
    setActiveIndex(index);
    e.currentTarget.blur();
  }

  const slides = items.map((item, index) => {
    return (
    <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.image}>
      <div className={style.border}>
      <Img className={style.customerImg} src={item.image} alt={item.name} />
      <div className={style.flex}>
        <p className={style.text}>
        {item.text}
        </p>
        <h3 className={style.header}>
          <strong>{item.name}</strong>&nbsp;
          {item.role}
        </h3>
      </div>
    </div>
    </CarouselItem>
    );
});

const logosSlide = items.map((item, index) => {
  return (
    <li className={style.logoItem} key={item.altText}>
    <button onClick={(e) => markLogo(e,index)} className={style.logoStyle}>
        {activeIndex === index ?  (
           <Img src={item.logo} alt={item.altText} />
        ): (
          <Img src={item.logoDisabled} alt={item.altText} />
        )}
    </button>
    </li>
  )
})
  return (
  <Container id="customers" color="gradient" ariaLabel="Clients say about us">
  <TextHeader color="primary">{customersSentence}</TextHeader>
  <div className={style.nextContainer}>
    <Button className={style.nextClick} cursorType='left' onClick={previous}>Prev</Button>
  </div>
  <div  className={style.prevContainer}>
    <Button className={style.prevClick} cursorType='right' onClick={next}>Next</Button>
  </div>
  <Carousel
      activeIndex={activeIndex}
      next={next}
      fade={true}
      slide={false}

      previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
      </Carousel>
      <ul className={style.logosList}>
        {logosSlide}
      </ul>
  </Container>
  );
};


Carousel.propTypes = {
  // the current active slide of the carousel
  activeIndex: PropTypes.number,
  // a function which should advance the carousel to the next slide (via activeIndex)
  next: PropTypes.func.isRequired,
  // a function which should advance the carousel to the previous slide (via activeIndex)
  previous: PropTypes.func.isRequired,
  // controls if the left and right arrow keys should control the carousel
  keyboard: PropTypes.bool,
  /* If set to "hover", pauses the cycling of the carousel on mouseenter and resumes the cycling of the carousel on
   * mouseleave. If set to false, hovering over the carousel won't pause it. (default: "hover")
   */
  pause: PropTypes.oneOf(['hover', false]),
  // Autoplays the carousel after the user manually cycles the first item. If "carousel", autoplays the carousel on load.
  // This is how bootstrap defines it... I would prefer a bool named autoplay or something...
  ride: PropTypes.oneOf(['carousel']),
  // the interval at which the carousel automatically cycles (default: 5000)
  // If set to false, carousel will not Autoplay (i.e. will not automatically cycle).
  interval: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.array,
  // called when the mouse enters the Carousel
  mouseEnter: PropTypes.func,
  // called when the mouse exits the Carousel
  mouseLeave: PropTypes.func,
  // controls whether the slide animation on the Carousel works or not
  slide: PropTypes.bool,
  cssModule: PropTypes.object,
  // controls whether the touch gestures on the Carousel works or not (default: true)
  enableTouch: PropTypes.bool,
};

export default Customers;
