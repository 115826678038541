import React from 'react';
import TextHeader from '../TextHeader/TextHeader';
import dev from './assets/dev.png';
import dev2 from './assets/dev.png';
import dev3 from './assets/dev.png';
import consultant from './assets/consultant.png';
import consultant2 from './assets/consultant2x.png';
import consultant3 from './assets/consultant3x.png';
import InnerLayout from '../Layout/InnerLayout';
import * as style from './aboutUs.module.scss';
import cx from 'classnames';
import { Picture } from 'react-responsive-picture';
import { Parallax } from 'react-scroll-parallax/cjs';
import Img from '../Img/Img';
import { isMobile } from 'react-device-detect';
import Container from '../Container/Container';

const AboutUs = () => {

    const devOffset = !isMobile ? [-90,30] : [-90,50];
    const consultOffset = !isMobile ? [-60, 30] : [-60, 50];

    return (

        <Container id="service" className={style.container} color="white" ariaLabel="What we offer section">
            <TextHeader color="primary" className={style.headerBoxShadow}>Expert Crafters</TextHeader>
            <InnerLayout>
                <div className={cx(style.item, style.development)}>
                    <div className={style.content}>
                        <h3 className={style.header} tabIndex="1">R&D Services</h3>
                        <p className={style.text} tabIndex="1">
                            We deliver top tier  front-end & full-stack R&D services. 
                            Our aim is to deliver exceptional quality while keeping you 
                            fully involved in the process, just like partners.  
                        </p>
                    </div>
                    <div className={style.parallexImageContainer}>
                        <div className={style.blurBackground} />

                        <Parallax className="parallex-dev-class" y={devOffset} tagOuter="figure">
                            < Img className={style.devImage} src={`${dev}, ${dev2} 2x, ${dev3} 3x`} tag={Picture} alt="parallex development image"/>
                        </Parallax>
                    </div>

                </div>
                <div className={cx(style.item, style.consultant)}>
                    <div className={style.content}>
                        <h3 className={style.header} tabIndex="1">Consulting Experts</h3>
                        <p className={style.text} tabIndex="1">
                            Our Consulting Experts can share best practices, avoid common pitfalls and 
                            help you boost your project while saving exepnsive development hours.
                        </p>
                    </div>
                    <div className={style.parallexImageContainer}>
                        <div className={style.blurBackground} />
                        <Parallax className="parallex-consolt-class" y={consultOffset} tagOuter="figure">
                            < Img className={style.devImage} src={`${consultant}, ${consultant2} 2x, ${consultant3} 3x`} tag={Picture} alt="parallex Consultant image"/>
                        </Parallax>
                    </div>

                </div>
            </InnerLayout>

        </Container>
    );
};

export default AboutUs;
