import React from 'react';
import PropTypes from 'prop-types';
import * as style from './innerLayout.module.scss';
const InnerLayout = ({ children }) => {
  return <section className={style.inner}>{children}</section>;
};

InnerLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InnerLayout;
