// extracted by mini-css-extract-plugin
export var item = "aboutUs-module--item--PEtkR";
export var development = "aboutUs-module--development--IQxiX";
export var parallexImageContainer = "aboutUs-module--parallex-image-container--2ydhx";
export var consultant = "aboutUs-module--consultant--4fSxY";
export var blurBackground = "aboutUs-module--blur-background--Ljv4q";
export var container = "aboutUs-module--container--NDoT6";
export var devImage = "aboutUs-module--dev-image--ybbgZ";
export var consultantImage = "aboutUs-module--consultant-image--aIFXp";
export var content = "aboutUs-module--content--BDUxw";
export var header = "aboutUs-module--header--eUAQx";
export var text = "aboutUs-module--text--O6wwh";
export var headerBoxShadow = "aboutUs-module--header-box-shadow--p56m3";