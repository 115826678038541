import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as style from './textHeader.module.scss';

const TextHeader = props => {
  const { color, className, children } = props;
  return (
    <h2
      className={cx(style.bigHeader, style[color], className)}
      title={children.toString()}
      tabIndex="1"
    >
      {children.toString()}
    </h2>
  );
};

TextHeader.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node,
  className: PropTypes.string
};

export default TextHeader;
