import React, { Component } from 'react';
import InnerLayout from '../Layout/InnerLayout';
import * as style from './hero.module.scss';
import { boundClass } from 'autobind-decorator';
import AnchorLink from '../AnchorLink/AnchorLink';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import DirectionButton from '../ArrowDirectionButton/DirectionButton';

@boundClass
class Hero extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opacity: '',
            left: '',
            top: '',
            boxShadow: ''
        };
    }

    mouseMoveHandler(event) {
        if (isMobile) return;
            event.persist();
            const blurPicture = document.querySelector(`.${style.blurPicture}`);
            const blurPicHalfWidth = blurPicture.offsetWidth / 2;
            this.setState({
                opacity: 1,
                left: `${event.pageX - blurPicHalfWidth}px`,
                top: `${event.pageY - blurPicHalfWidth}px`,
                boxShadow: '10px 0 50px 40px #fff inset',
            });

    }

    mouseOutHandler() {
        if (isMobile) return;
            this.setState({
                opacity: 0,
                boxShadow: '0 0 0 0 #fff inset',
            });
    }

    render() {

        return (
            <section
                className={style.container}
                id="home"
                onMouseMove={this.mouseMoveHandler}
                onMouseOut={this.mouseOutHandler}

            >
                <InnerLayout>
                    <div
                        className={cx(style.blurPicture)}
                        style={{
                            opacity: this.state.opacity,
                            left: this.state.left,
                            top: this.state.top,
                            boxShadow: this.state.boxShadow,
                        }}
                    />
                    <div className={style.contentWrapper} aria-label="home section">
                        <h2 className={style.header} aria-label="hero header" tabIndex="1">We Craft Code</h2>
                        <p className={style.descriptionText} tabIndex="1">
                            Webiya specializes in front-end & full-stack R&D and Consulting Services. 
                            We strive to provide our clients with professional and advanced solutions for their needs.
                        </p>
                        <AnchorLink tabIndex="1" cursorType="pointer" id="contact" color="secondary">CONTACT US</AnchorLink>
                        <DirectionButton direction="down" id="service" />
                    </div>
                </InnerLayout>
                <AnchorLink tabIndex="-1" cursorType="bottom" id="service" className={style.bottomArrow}>Scroll down</AnchorLink>
            </section>
        );
    }
}

export default Hero;
