import React, { Component } from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/seo';
import Hero from '../components/Hero/Hero';
import AboutUs from '../components/AboutUs/AboutUs';
import Clients from '../components/Clients/Clients';
import WhyUs from '../components/WhyUs/WhyUs';
import Technologies from '../components/Technologies/Technologies';
import ContactUs from '../components/ContactUs/ContactUs';
import CursorProvider from '../Providers/CursorProvider';
import DirectionButton from '../components/ArrowDirectionButton/DirectionButton';
import Customers from '../components/Customers/Customers';
import Projects from '../components/Projects/Projects';

class Hp extends Component {

    render() {
        return (
            <CursorProvider>
                <Layout>
                    <Seo title="Home"/>
                    <Hero/>
                    <AboutUs/>
                    <WhyUs/>
                    <Technologies/>
                    <Customers/>
                    <ContactUs cursorType="secondary"/>
                    <DirectionButton direction="up" id="home" />
                </Layout>
            </CursorProvider>
        );
    }

}

export default Hp;
