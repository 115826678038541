import React, { Component } from 'react';
import TextHeader from '../TextHeader/TextHeader';
import facebook from './assets/facebook.svg';
import shareIn from './assets/linked-in.svg';
import twitter from './assets/twitter.svg';
import * as style from './contactUs.module.scss';
import { boundClass } from 'autobind-decorator';
import Img from '../Img/Img';
import LightBox from '../LightBox/LightBox';
import withCursor from '../../HOCs/withCursor';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Container from '../Container/Container';

const socialDataList = [
    {
        url: 'https://www.facebook.com/Webiya',
        image: facebook,
        alt: 'Facebook icon',
    },
    {
        url: 'https://www.linkedin.com/company/webiya',
        image: shareIn,
        alt: 'In icon',
    },
];

const contactDataList = [
    {
        url: 'mailto:info@webiya.co.il',
        text: 'info@webiya.co.il'
    },
    {
        url: 'tel:+972546490123',
        text: '+972546490123'
    },
    {
        url: 'http://maps.google.com/?q=23 Menachem Begin, Tel Aviv, Israel',
        text: '23 Menachem Begin, Tel Aviv, Israel'
    },
];

const initialState = {
    nameInput: '',
    emailInput: '',
    bodyInput: '',
    nameInputValid: true,
    emailInputValid: true,
    bodyInputValid: true,
};

@boundClass
class ContactUs extends Component {

    constructor(props) {
        super(props);


        this.state = {
            ...initialState,
            formValid: false,
            openBg: false

        };
    }

    handleChange(e) {
        e.persist();
        const name = e.target.name;
        const value = e.target.value;
        const inputElement = document.querySelector(`input[name=${name}]`);
        this.setState({ [name]: value },
            () => {
                this.validateField(name, value, inputElement);
            });
    }

    validateField(fieldName, value, inputElement) {
        let nameValid = this.state.nameInputValid;
        let emailValid = this.state.emailInputValid;
        let bodyValid = this.state.bodyInputValid;

        switch (fieldName) {
            case 'nameInput':
                nameValid = inputElement.checkValidity();
                break;
            case 'emailInput':
                emailValid = inputElement.checkValidity();
                break;
            case 'bodyInput':
                bodyValid = inputElement.checkValidity();
                break;
            default:
                break;
        }

        this.setState({
            nameInputValid: nameValid,
            emailInputValid: emailValid,
            bodyInputValid: bodyValid,
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        const formValid = this.state.nameInputValid && this.state.emailInputValid && this.state.bodyInputValid;
        if (!formValid) return;
        const data = {
            name: this.state.nameInput,
            email: this.state.emailInput,
            message: this.state.bodyInput
        };
        await fetch('../../../api/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        this.setState({
            ...initialState,
            formValid: formValid,
            openBg: true
        });

    }

    handleCloseBox() {
        this.setState({ openBg: false, formValid: false });
    }

    render() {
        return (
            <section
                onMouseEnter={() => this.props.context.onCursor(this.props.cursorType)}
                onMouseLeave={this.props.context.onCursor}
            >
                <Container color="red" className={style.container} id="contact" ariaLabel="Contact us section">
                    <div className={cx(style.opacityBackground, { [style.active]: this.state.openBg })}/>
                    <TextHeader color="secondary">Let's Talk</TextHeader>
                    <div className={style.content} aria-label="Form section">
                        {/*<form onSubmit={this.handleSubmit} tabIndex="1">*/}
                        {/*    <input*/}
                        {/*        className={cx(style.nameInput, { [style.errorColor]: !this.state.nameInputValid })}*/}
                        {/*        type="text"*/}
                        {/*        name="nameInput"*/}
                        {/*        value={this.state.nameInput}*/}
                        {/*        placeholder="Your Name"*/}
                        {/*        pattern="[a-zA-Zא-ת]+[ ][a-zA-Zא-ת\s]+"*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*        tabIndex="1"*/}
                        {/*        aria-label="Name input"*/}
                        {/*        title="You need one space at least"*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*    <input*/}
                        {/*        className={cx(style.emailInput, { [style.errorColor]: !this.state.emailInputValid })}*/}
                        {/*        type="email"*/}
                        {/*        name="emailInput"*/}
                        {/*        value={this.state.emailInput}*/}
                        {/*        placeholder="Your Email"*/}
                        {/*        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*        tabIndex="1"*/}
                        {/*        aria-label="Email input"*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*    <input*/}
                        {/*        className={cx(style.bodyInput, { [style.errorColor]: !this.state.bodyInputValid })}*/}
                        {/*        type="text"*/}
                        {/*        name="bodyInput"*/}
                        {/*        value={this.state.bodyInput}*/}
                        {/*        placeholder="How can we help you?"*/}
                        {/*        pattern="[a-zA-Zא-ת\s]+"*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*        tabIndex="1"*/}
                        {/*        aria-label="Body input"*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*    <button*/}
                        {/*        className={style.submitButton}*/}
                        {/*        type="submit"*/}
                        {/*        tabIndex="1"*/}
                        {/*        aria-label="Submit button"*/}
                        {/*        onClick={(e) => e.currentTarget.blur()}*/}
                        {/*    >send*/}
                        {/*    </button>*/}
                        {/*</form>*/}
                        <div className={style.contactInfo} aria-label="Contact info section">

                            {contactDataList.map(({ url, text }) => (
                                <a
                                    className={style.contactLink}
                                    target="_blank"
                                    href={url}
                                    key={text}
                                    tabIndex="1"
                                >
                                    {text}
                                </a>
                            ))}

                            <ul className={style.contactSocials} aria-label="Social links section">
                                {socialDataList.map(({ url, image, alt }) => (
                                    <li className={style.socialItem} key={alt} aria-label={`${alt} item`}>
                                        <a className={style.shareLink} href={url} tabIndex="1">
                                            <Img src={image} alt={alt}/>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <LightBox handleCloseBox={this.handleCloseBox} className={{ 'showLightBox': this.state.formValid }}/>
                </Container>
            </section>

        );
    }
}

ContactUs.propTypes = {
    cursorType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default withCursor(ContactUs);
