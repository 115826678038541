import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as style from './iconedText.module.scss';
import Img from '../Img/Img';
import { boundClass } from 'autobind-decorator';

@boundClass
class IconedText extends Component {

    render() {
        return (
            <div className={style.contentItem} aria-label="Why us item" tabIndex="1">

                {this.props.children}
                <div className={cx(style.textItem, this.props.itemClassName)}>
                    <h2 className={style.textHeadline}>{this.props.title} </h2>
                    <p className={style.elementText}>{this.props.content}</p>
                </div>
                <Img className={style.imageItem} src={this.props.image} alt="icon element"/>
            </div>
        );
    }
}

IconedText.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string,
    children: PropTypes.node
};

export default IconedText;
