import React from 'react';
import NodeJSLogo from './assets/NodeJS.svg';
import reactLogo from './assets/react.svg';
import MongoDBLogo from './assets/MongoDB.svg';
import largeScale from './assets/large-scale.svg';
import TextHeader from '../TextHeader/TextHeader';
import IconedText from '../IconedText/IconedText';
import * as style from './technologies.module.scss';
import Container from '../Container/Container';

const Technologies = () => {
  const elementsData = [
    {
      title: 'React & React Native.',
      content: 'Creating responsive and performant web and native applications by using the world\'s most popular UI framework.',
      image: reactLogo,
    },
    {
      title: 'NodeJS.',
      content: 'Building the right backend for your application with the power of concurrent and non-blocking javascript.',
      image: NodeJSLogo,
    },
    {
      title: 'MongoDB.',
      content: 'The modern database for getting your ideas to market in a seamless and prominent manner.',
      image: MongoDBLogo,
    },
    {
      title: 'Keystone.',
      content: 'When in need of content management, Keystone is a strong player towards making it a reality.',
      image: largeScale,
    },
  ];

  return (
    <Container className={style.container} color="white" id="tech" ariaLabel="Technologies section">
      <TextHeader color="primary">Technologies</TextHeader>
      <div className={style.contentWrapper}>
        {elementsData.map(({ title, content, image }, index) => (
          <IconedText
            key={`tech${index}`}
            title={title}
            content={content}
            image={image}
            itemClassName={style.textItem}
          />
        ))}
      </div>
    </Container>
  );
};

export default Technologies;
