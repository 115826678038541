// extracted by mini-css-extract-plugin
export var text = "customers-module--text--7d8dZ";
export var header = "customers-module--header--MAWkA";
export var logosList = "customers-module--logosList--steN5";
export var arrow_right = "customers-module--arrow_right--ck6mi";
export var logoItem = "customers-module--logoItem--LdFn3";
export var customerImg = "customers-module--customerImg--jczr6";
export var border = "customers-module--border--VHnnk";
export var flex = "customers-module--flex--70eTx";
export var nextClick = "customers-module--nextClick--2FYRT";
export var prevClick = "customers-module--prevClick--J2edM";
export var nextContainer = "customers-module--nextContainer--BvRFL";
export var prevContainer = "customers-module--prevContainer--ot0Zn";
export var logoStyle = "customers-module--logoStyle--3H0uz";