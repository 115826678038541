import React, { Component } from 'react';
import checkShape from './assets/check-shape.svg';
import closeLightBoxImg from './assets/close-lightBox.svg';
import * as style from './lightBox.module.scss';
import cx from 'classnames';
import { boundClass } from 'autobind-decorator';
import Button from '../Nav/Button';
import PropTypes from 'prop-types';

@boundClass
class LightBox extends Component {

    constructor(props) {
        super(props);
    }

    handleChange() {
        this.props.handleCloseBox();
    }


    render() {
        return (
            <section className={cx(style.container, this.props.className)} aria-label="Thank you popup window">
                <div className={style.contentWrapper} tabIndex="1">
                    <Button
                        className={style.closeButton}
                        onClick={this.handleChange}
                        cursorType="button"
                        tabIndex="1"
                    >
                        <img src={closeLightBoxImg} alt="close light box icon"/>
                    </Button>

                    <img className={style.shapeImage} src={checkShape} alt="shape"/>

                    <div className={style.textContent} aria-label="Thank you text section">
                        <h3 className={style.header} tabIndex="1">Thank you!</h3>
                        <h4 className={style.subHeader} tabIndex="1">
                            Thanks for reaching out, we'll contact you real soon!
                        </h4>
                        <p className={style.text} tabIndex="1">
                            Till then, you are more than welcome to visit our
                            <strong> Facebook </strong> and
                            <strong> twitter </strong>
                            pages.
                        </p>
                        <p className={style.text} tabIndex="1">Talk to you soon…</p>
                    </div>
                </div>
            </section>
        );
    }
}

LightBox.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default LightBox;
